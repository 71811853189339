.quick-actions {
  z-index: 1000;

  position: fixed;
  right: 3.6rem;
  bottom: 3.6rem;

  transition: all 350ms;

  &--open {
    .quick-actions {
      &__overlay {
        transform: scale(100);
      }

      &__button {

        background-color: var(--color-red);
        span {
          transform: rotate3d(0, 0, 1, 45deg);
        }
      }

      &__list {
        max-height: 400px;
        opacity: 1;
        visibility: visible;

        transition: max-height 200ms, opacity 700ms;
      }
    }
  }

  &__overlay {
    position: fixed;
    right: 3.6rem;
    bottom: 3.6rem;

    transition: all 500ms;

    width: 4rem;
    max-width: 4rem;
    height: 4rem;
    max-height: 4rem;

    border-radius: 50%;

    background-color: #FFFFFFD0;

    transform: scale(1);

  }

  &__button {
    position: fixed;
    right: 3.6rem;
    bottom: 3.6rem;

    width: 8rem;
    height: 8rem;

    border-radius: 50%;

    cursor: pointer;

    span {
      display: block;

      font-size: 6.4rem;
      font-weight: 300;

      transition: transform 250ms;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    list-style-type: none;

    position: relative;

    margin-bottom: 10rem;

    transition: max-height 350ms, opacity 100ms;

    max-height: 0;
    visibility: hidden;
    opacity: 0;
  }

  &__item {

  }


}