.grid {
  display: grid;

  section {
    padding: 1.6rem;

    background-color: var(--color-blue);
    color: var(--color-black);

    &:nth-child(2n) {
      background-color: var(--color-red);
    }

    &:nth-child(3n) {
      background-color: var(--color-yellow);
    }

    &:nth-child(4n) {
      background-color: var(--color-green);
    }

    border-radius: 1.4rem;

    &.col-span-2 {
      grid-column-end: span 2;

      @media only screen and (max-width: 567px) {
        grid-column-end: span 1;
      }
    }
  }


  &--dashboard {
    grid-template-columns: repeat(auto-fill, minmax(35rem, 1fr));
    grid-template-rows: repeat(auto-fill, minmax(100px, 1fr));

    grid-gap: 2rem;

    grid-auto-flow: dense;
  }
}