h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;

  line-height: 1.2;

  display: flex;
  align-items: center;

  gap: 1rem;
}

h1, .h1 {
  font-size: 5.6rem;
  line-height: 1;
}

h2, .h2 {
  font-size: 3.8rem;
  line-height: 1.2;

}

h3, .h3 {
  font-size: 3.2rem;
  line-height: 1.2;

  justify-content: space-between;

  margin-bottom: 1.6rem;

  svg {
    width: 3.4rem;
    height: 3.4rem;
  }
}

h4, .h4 {
  font-size: 2.4rem;
  line-height: 1.2;
  font-weight: 600;
}