$rebecca: #663399;

$rebecca-shadow: rgba(102, 51, 153, .5);


$badass: #bada55;

html {

  --amethyst: #9b5de5ff;
  --magenta-crayola: #f15bb5ff;
  --minion-yellow: #fee440ff;
  --capri: #00bbf9ff;
  --sea-green-crayola: #00f5d4ff;
  --raisin-black: #212738ff;
  --ghost-white: #f7f7ffff;
  --red-orange-color-wheel: #fb5012ff;
  --orange-soda: #F55D3E;
  --davys-grey: #585C5Fff;

  --color-white: var(--ghost-white);
  --color-black: var(--raisin-black);
  --color-green: var(--sea-green-crayola);
  --color-yellow: var(--minion-yellow);
  --color-blue: var(--capri);
  --color-blue-light: var(--capri);
  --color-red: var(--magenta-crayola);
  --color-purple: var(--amethyst);
  --color-orange: var(--orange-soda);
  --color-grey: var(--davys-grey);


  --rebecca: #663399;
  --rebecca-shadow: rgba(102, 51, 153, .5);
  --badass: #bada55;
}