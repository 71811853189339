@import "variables";
@import "global";

@import "core/typography";
@import "core/grid";

@import "core/header";
@import "core/table";
@import "core/progress";
@import "core/quick-actions";

@import "vendor-override";

body > * {
  padding: 2rem 0;
  width: 100%;
  max-width: 92vw;
  margin: 0 auto;
}

/*main {
  //font-size: 2.2rem;
  //background-color: var(--badass);
  //border-radius: 1rem;
  //
  //padding: 40px 20px;
}

section {
  border: 1rem solid var(--rebecca);
  border-radius: 50%;
  width: 100%;
  //max-width: clamp(20rem, 35vw, 50vw);
  aspect-ratio: 1 / 1;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  font-size: 3rem;
}

section:hover {
  background-color: #663399F0;
  transform: scale(1.01) translateY(-3px);
  box-shadow: .2rem .2rem 1.5rem 0 var(--rebecca-shadow);
}

section span {
  //font-size: clamp(16rem, 13.0000rem + 8.3333vw, 20rem);
  //line-height: clamp(10rem, 7.0000rem + 8.3333vw, 14rem);
  font-weight: 300;

  margin-bottom: clamp(1.4rem, 2.4rem, 3rem);
}*/

.btn-group {
  margin-top: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
}

.btn {
  font-size: 3rem;
  line-height: 3rem;

  background-color: var(--color-green);

  border: none;

  transition: all 250ms ease-in-out;

  &--circle {
    padding: 2rem;

    border-radius: 50%;
    aspect-ratio: 1 / 1;

    flex: 0 0 calc(33% - 20px);
  }

  &.disabled {
    background-color: #d3d3d3;
    pointer-events: none;

    &, &:hover, &:focus {
      box-shadow: none;
      transform: none;
    }
  }

  &:hover {
    transform: scale(1.01) translateY(-3px);
    box-shadow: 1px 1px 15px -3px var(--rebecca-shadow);
  }

  &:focus {
    outline: .1rem solid var(--rebecca);
    box-shadow: inset 1px 1px 15px -3px var(--rebecca-shadow);
  }
}