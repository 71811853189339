header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  padding: 0 0 1.6rem;
  text-align: center;

  h1, h2 {
    font-weight: 600;
    line-height: 1;

    letter-spacing: 5px;
  }

  .header__logo {
    &, &:link, &:visited {
      text-decoration: none;

      color: $rebecca;
    }
  }

  a {
    position: relative;

    &, &:link, &:visited {
      text-decoration: none;

    }

    &::after {
      content: "";
      position: absolute;
      width: 0;
      left: 0;
      bottom: 0;
      height: 2px;
      background-color: currentColor;

      transition: all 25ms cubic-bezier(.4, .08, 0, .97);
    }

    &:hover, &:focus {
      //text-decoration: underline;

      &::after {
        width: 100%;
      }
    }
  }

  h3 {
    gap: .5rem;

    a {
      &, &:link, &:visited {
        color: var(--color-blue);

      }
    }
  }

  .page-title {
    font-size: 4.8rem;

    color: $badass;
  }
}