.progress-bar {

  &:not(:last-of-type) {
    margin-bottom: 1.2rem;
  }

  &__progress {
    padding-top: .4rem;

    display: flex;
    justify-content: space-between;
    gap: 2rem;

    progress[value] {
      -webkit-appearance: none;
      appearance: none;

      flex: 1 1 100%;
      height: 3rem;

      &::-webkit-progress-bar {
        background-color: var(--color-white);
        border-radius: 4px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
      }

      &::-webkit-progress-value {
        background-color: var(--color-purple);

        border-radius: 4px;
        background-size: 35px 20px, 100% 100%, 100% 100%;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
      }

      & + span {
        font-size: 2.4rem;
        line-height: 3rem;
      }
    }
  }
}

